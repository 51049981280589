.shieldLayout{
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 0.5rem;
    padding-bottom: 3.2rem;
}
.shieldLayout>.arrowBack{
  width: 36px;
  margin-bottom: 12px;
}
.shieldContent>p{
  margin-bottom: 1rem;
}
