.spaService > h5 {
    font-size: 18px;
    margin-top: 16px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.2;
  }

  .spaService > span {
    display: flex;
    height: 4px;
    width: 30%;
    background-color: #d8d8d8;
    margin-bottom: 24px;
  }