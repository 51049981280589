.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.64);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-dialog {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin: 0;
    max-width: 900px;
    min-height: calc(100% - 0.5px);
    outline: none;
    pointer-events: none;
    width: 100%;

    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        outline: 0;
        padding: 0;
        border-radius: 0;
        box-sizing: border-box;

        .modal-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.75rem 1rem;
            border-bottom: 1px solid #ececec;
            .btn-icon {
                border: none;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: transparent;

                color: #000;
                line-height: 18px;
                .fa {
                    font-weight: 600;
                    font-size: 1.25rem;
                }
            }
        }
        .modal-body {
            padding: 0.5rem 1rem 1rem;
            background-color: #fff;
            max-height: 18rem;
            overflow: auto;
            min-height: 18rem;
        }
        .modal-footer {
            border-top: 1px solid #ececec;
            background: #fff;
            padding: 0.5rem 1rem;
            width: 100%;
        }
    }
}
