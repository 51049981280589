.history-wrapper {
  width: 100%;
  padding: 0 1rem 2rem;
}
.booking-header {
  width: 100%;
  justify-content: flex-start;
  display: flex;
}
.history-container {
  width: 100%;
}

.history_card {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px dashed #75716f;
  margin-bottom: 1rem;
  padding: 0.75rem;
  border-radius: 8px;

  .service-title {
    color: #344050;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 16px;
    line-height: 1.2;
    text-transform: capitalize;
  }
  .order-id {
    color: #000;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 12px;
    font-family: inherit;
  }
  .service-time,
  .service-date,
  .service-guest-count {
    font-size: 13px;
    color: #616871;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    span {
      font-weight: 600;
    }
  }
  .service-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    .btn-cancel,
    .btn-reschedule {
      width: 100%;
      letter-spacing: 0.4px;
      font-size: 14px;
      font-weight: 500;
      align-items: center;
      border: 1px solid var(--primary-color);
      border-radius: 7px;
      padding: 10px 15px;
      background: #fff;
      color: #000000;
      font-family: inherit;

      &.disabled {
        opacity: 0.3;
        cursor: none;
        pointer-events: none;
      }
    }
    .btn-cancel {
      background: #c66974;
      color: #fff;
    }
    .btn-reschedule {
      margin-left: 5px;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.canceled {
    opacity: 0.3;
    pointer-events: none;
    cursor: none;
    border-color: #c66974;
  }
}
