.login {
  background-color: var(--primary-color);
  color: #fff;
  font-size: 14px;
  border: none;
  outline: none;
  padding: 12px 36px;
  text-transform: uppercase;
  border-radius: 8px;
}
.add {
  border: 1px solid #696969;
  border-radius: 5px;
  padding: 6px 15px;
  width: fit-content;
  background-color: transparent;
  color: #696969;
  font-size: 13px;
  font-weight: 500;
  font-family: inherit;
}
.book {
  border: 1.5px solid #255e71;
  border-radius: 5px;
  padding: 6px 15px;
  width: fit-content;
  background-color: #fff;
  color: #255e71;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}
.back {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  background-color: #f4f5f8;
  filter: drop-shadow(0px 3px 2px #69696950);
  display: flex;
  padding: 10px 30px;
  border: none;
  border-radius: 4px;
}
.proceed {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  background-color: var(--primary-color);
  filter: drop-shadow(0px 3px 2px #69696950);
  display: flex;
  padding: 10px 30px;
  border: none;
  border-radius: 4px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeRequest {
  background-color: var(--primary-color);
  font-size: 13px;
  color: #fff;
  padding: 10px 16px;
  border-radius: 20px;
  width: fit-content;
  border: none;
  filter: drop-shadow(3px 3px 6px #00000030);
  font-family: inherit;
}

.disabled {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  opacity: 0.5;
  display: flex;
  padding: 10px 30px;
  border: none;
  border-radius: 4px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bcbcbc;
  color: #8a8a8a;
  cursor: not-allowed;
}

button:focus {
  outline: none;
}
