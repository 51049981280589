.categoryContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .subcategoryName {
        margin: 0;
        font-size: 16px;
        color: #696969;
        letter-spacing: 1px;
        text-transform: uppercase;
        max-width: 80%;
        text-align: center;
        background: #f3f3f3;
        z-index: 1;
        padding: 0 10px;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        background: #696969;
        width: 100%;
        height: 1px;
        transform: translateY(-50%);
    }
}