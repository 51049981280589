.bottomNav {
    position: fixed;
    padding: 0.75rem 1rem;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    left: 0;
    right: 0;
    background-color: #ffffffe6;
    border-top: 1px solid #69696920;
    font-family: inherit;
    z-index: 2;
    direction: ltr;
}

.badge {
    position: absolute;
    background-color: red;
    color: #fff;
    height: 16px;
    width: 16px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 10%;
    bottom: 24px;
}
