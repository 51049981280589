.laundryLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0.5rem;
  padding-bottom: 3.2rem;
  background-color: #f3f3f3;
}
.laundryLayout > .arrowBack {
  width: 36px;
  margin-bottom: 0px;
}
.laundryLayout > p {
  font-size: 14px;
}
.cartCounterWrapper {
  z-index: 9;
  position: fixed;
  bottom: 45px;
  width: 100%;
  background: #fff;
  padding: 1rem 0;
  left: 0px;
  padding: 0.5rem 1rem;
  border: none;
}
.laundryService > laundryn {
  display: flex;
  height: 4px;
  width: 30%;
  background-color: #d8d8d8;
  margin-bottom: 24px;
}
.menuWrapper {
    position: fixed;
    right: 0px;
    bottom: 9rem;
    overflow: scroll;
    z-index: 9998;
    background: #fff;
    min-width: 9rem;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    max-height: 17rem;
}

.overlay {
  position: fixed;
  background-color: #fff;
  filter: brightness(0.2);
  opacity: 0.6;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
.noOverlay {
  display: none;
}

.menuMainTitle {
  margin-bottom: 0.5rem;
}

.menuMainTitle > h5 {
  font-weight: 500;
  font-size: 16px;
}
