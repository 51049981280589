.checkoutLayout {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2px 20px;
  position: relative;
  height: 100%;
  min-height: 80vh;
  padding-top: 1rem;
  padding-bottom: 2rem;
}
.checkoutLayout > h3 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 0;
}
.checkoutLayout > p {
  font-size: 12px;
}
.guestsCounter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.guestsCounter > p {
  font-size: 12px;
}
.closeButton {
  position: fixed;
  top: 15px;
  right: 20px;
  z-index: 99999;
  font-size: 18px;
  font-weight: 500;
}
.pickUpLaundry {
  display: flex;
  align-items: center;
  padding: 24px 0;
  border-top: 2px solid #696969;
}
.pickUpLaundry > p {
  font-size: 12px;
  margin: 0 0 0 12px;
}
.timeInput {
  height: 32px;
  color: #696969;
  width: 100%;
  border: none;
  padding: 1px;
  background-color: #f3f3f3;
}
.timeInput::-webkit-datetime-edit {
  -webkit-flex: 1;
  -webkit-user-modify: read-only !important;
  display: inline-block;
  min-width: 0;
  overflow: hidden;
}

.timeInput::-webkit-datetime-edit-fields-wrapper {
  -webkit-user-modify: read-only !important;
  display: inline-block;
  padding: 1px 0;
  white-space: pre;
}

.selectTime {
  font-weight: 600;
  font-size: 14px;
  margin-top: 0;
}
.checkoutBox {
  height: 64px;
  position: fixed;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 9;
  display: flex;
  justify-content: space-around;
  align-items: center;
  filter: drop-shadow(0px -2px 6px #69696960);
}

.spaCheckoutTitle {
  margin-bottom: 24px;
}
.spaCheckoutService {
  margin: 0;
}
.spaPrice {
  font-weight: 500;
  margin: 6px 0;
  margin-bottom: 24px;
}
.divider {
  display: flex;
  height: 2px;
  background-color: #696969;
  margin-bottom: 24px;
}

.guestsCounterSpa {
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.guestsCounterSpa > p {
  font-size: 13px;
  font-weight: 500;
}
.selected {
  margin-bottom: 24px;
}

.emptyCartScreen {
  justify-content: center;
  height: 62vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
}

.proceedSpaWrapper {
  position: absolute;
  top: 80vh;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.timeInputCheckbox {
  background-color: #7d9371;
}
.placeRequestContentIntro {
  font-size: 16px !important;
  font-weight: 500;
}
.placeRequestContent {
  font-size: 13px !important;
  font-weight: 500;
  margin: 6px 0;
}
.placeRequestContentBold {
  width: 100%;
}
.roomNumber {
  display: flex;
  justify-content: space-between;
}

.roomNumberNoBorder {
  display: flex;
  justify-content: space-between;
}
.roomNumberNoBorder > p {
  margin: 0;
}
.errorMessage {
  background-color: gold;
  color: #000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 4px;
  font-size: 12px;
  padding-left: 20px;
}
.payNowWindow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.price__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  width: 100%;
}
.price {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 0.9rem;
  margin-bottom: 0.9rem;
  width: 100%;
}
.grand__pay__label {
  color: #1c1c1c;
  font-weight: 600;
  font-size: 1rem;
}

.grand__pay__val {
  color: #1c1c1c;
  font-weight: 600;
  font-size: 1rem;
}

.stepOne {
  background-color: #ffe8e6;
  border-radius: 8px;
  padding: 12px;
  width: 95%;
  font-size: 14px;
}
.stepOne > button {
  font-size: 12px;
}

.uploadFileWrapper {
  background-color: #fdf5b7;
  border-radius: 8px;
  padding: 12px;
  width: 95%;
  margin: 16px 0;
}
.uploadFileWrapper > p {
  font-size: 14px;
}
.uploadFileWrapper > input[type="file"] {
  display: none;
}

.uploadFile {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  background-color: var(--primary-color);

  filter: drop-shadow(0px 3px 2px #69696950);
  padding: 10px 16px;
  width: fit-content;
  border: none;
  border-radius: 4px;
}
.uploadFile > span {
  text-align: center;
}
.uploadFile > img {
  width: 24px;
  margin-right: 6px;
}
.stepThree {
  background-color: #e6f4d6;
  border-radius: 8px;
  padding: 12px;
  width: 95%;
  font-size: 14px;
}
.stepThree > button {
  font-size: 12px;
}
.stepThreeDisabled {
  background-color: #e6f4d6;
  border-radius: 8px;
  padding: 12px;
  width: 95%;
  font-size: 14px;
}
.stepThreeDisabled > button {
  font-size: 12px;
  opacity: 0.65;
}
.step {
  font-weight: 600;
}
.uploadedFileName {
  font-size: 12px !important;
  margin: 6px 0 0 0;
  font-style: italic;
}
.tax__val {
  color: darkgrey;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9rem;
  white-space: nowrap;
}
.price__label {
  color: #9c9c9c;
  font-size: 0.75rem;
}
.total {
  display: flex;
  background-color: #fff;
  box-sizing: border-box;
  padding: 5px 0;
  width: 100%;
  margin-bottom: 5px;
  align-items: center;
  justify-content: space-between;
}
.total > div > p {
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0;
}
.total > h3 {
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
}
.taxDisclaimer {
  margin: 0 0 5px 0;
  font-size: 9px;
  font-style: italic;
  font-weight: 500;
}
.checkoutFoodError {
  background-color: gold;
  right: 0;
  padding: 2px 4px;
  font-size: 12px;
  font-weight: 500;
  left: 0;
  position: fixed;
  bottom: 53px;
  margin: 0;
  z-index: 9;
}
.timeInputWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.timeInputWrapper > p {
  margin: 0;
}

.noLink {
  text-decoration: none;
}
