.homepage-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 3.2rem;
    &.lang-modal-open {
        filter: blur(2px);
    }
    .landing-navigation {
        width: 100%;
        padding: 0.25rem 1rem;
        min-height: 51px;
        max-height: 51px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 0;
        z-index: 9;
        background: #fffffff2;
       

        p {
            text-align: left;
            font-size: 1rem;
            font-weight: 500;
            font-style: italic;
            color: #818181;
        }
        .material-symbols-outlined {
            font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
            color: #75716f;
        }
    }
    .twoCardsPerRow {
        display: grid;
        grid-gap: 12px;
        grid-template-columns: repeat(2, 1fr);
    }
    .language-selector {
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .halfItem {
        width: 48%;
    }
    .homefeed-conainer {
        padding: 0;
        width: 100%;
        background-color: #161617;
    }
    .allservices-container ,.nested-menu-container, .orderFood-categories-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;
        padding: 0 0.5rem;
        padding-bottom: 0.5rem;
        .service_title {
            width: 100%;
            color: #fff;
            padding: 10px;
            background-image: linear-gradient(180deg, #ffffff00, #000000fa);
            padding-top: 3rem;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            font-size: 1rem;
            font-weight: 300;
            letter-spacing: 1px;
            // color: var(--primary-color);
        }
        .service {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            width: 100%;
            height: 160px;
            object-fit: cover;
            background-position: center center;
            background-size: cover;
            box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2);
            cursor: pointer;
            overflow: hidden;
            border-radius: 5px;
            margin-top: 0.5rem;

            video{
                width: 100% !important;
            }
            .hotel-name {
                color: #fff;
                font-size: 1.35rem;
                padding: 10px;
                font-weight: 200;
                letter-spacing: 1.5px;
                z-index: 1;
                padding-top: 3rem;
                width: 100%;
                background-image: linear-gradient(180deg, #ffffff00, #000000fa);
                span {
                    display: block;
                    font-weight: 600;
                    font-size: 1.75rem;
                    letter-spacing: 0px;
                }
            }
            // &:last-child {
            //     margin-bottom: 7px;
            // }
            @media only screen and (min-device-width: 375px) {
                height: 180px;
            }
            &.regular_square {
                width: 49%;
            }
            &.jumbo_tile {
                height: 333px;
            }
            &.mega_jumbo {
                height: 388px;
            }
            &.mini_jumbo {
                height: 250px;
            }
            &.apply-theme {
                .service_title,
                .hotel-name {
                    padding: 8px 10px;
                    background-color: var(--primary-color);
                    background-image: none;
                    font-size: 0.9rem;
                }
            }
        }
    }
}
