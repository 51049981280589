.spaCard {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 0.5rem;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    padding: 0.5rem;
    background-color: #fff;
    gap: 0.5rem;

    p {
        margin: 0;
    }

    .spa_banner {
        width: 100%;
        height: 144px;
        border-radius: 5px;
    }

    .spaCardp {
        font-size: 13px;
        font-weight: 500;
        color: #636362;
        margin: 2px 0;
        width: 100%;
    }

    .price {
        margin: 2px 0 0 0;
        font-weight: 600;
        font-size: 13px;
        white-space: nowrap;
    }

    .title_book_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 0.4rem;
        margin-top: 0.5rem;

        p {
            margin-right: 10px;
            font-weight: 600;
            font-size: 14px;
        }
    }

    .description {
        width: 100%;
        white-space: pre-wrap;
        font-family: inherit;
        font-size: 0.75rem;
        margin: 0;
        font-weight: 500;
        color: #727272;
        line-height: 20px;
    }
}

.cat-name-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 1rem 0;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        background: #696969;
        width: 100%;
        height: 1px;
        transform: translateY(-50%);
    }
    &.sub-cat {
        .cat-name {
            color: #000;
        }
        &::after {
            display: none;
        }
    }
}
.cat-name {
    margin: 0;
    font-size: 14px;
    color: #696969;
    letter-spacing: 1px;
    text-transform: uppercase;
    max-width: 70%;
    text-align: center;
    background-color: #f3f3f3;
    z-index: 1;
    padding: 0 10px;
    line-height: 1.2;
}
.category-description {
    width: 100%;
    white-space: pre-wrap;
    font-family: inherit;
    font-size: 0.85rem;
    margin: 0.2rem;
    font-weight: 500;
    color: #727272;
    line-height: 20px;
}
