.checkboxWrapper {
  padding: 10px 0;
  border-bottom: 1px solid #c0c0c050;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.checkboxWrapper > input {
  height: 20px;
  width: 20px;
}
.checkboxWrapper > input:checked {
  background-color: var(--primary-color) !important;
}
.checkboxWrapper > label {
  font-size: 13px;
  margin-left: 10px;
}
.checkboxAddonWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.2rem;
}
.checkboxAddonWrapper > input {
  height: 20px;
  width: 20px;
}
.checkboxAddonWrapper > input:checked {
  background-color: var(--primary-color) !important;
}

.checkboxAddonWrapper > label {
  font-size: 13px;
  margin-left: 10px;
}
