.spaLayout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 0.5rem;
    padding-bottom: 9rem;
    background-color: #f3f3f3;
}

.spaLayout > .arrowBack {
  width: 36px;
  margin-bottom: 12px;
}

.spaService > h5 {
  font-size: 18px;
  margin-top: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2;
}

.spaService > span {
  display: flex;
  height: 4px;
  width: 30%;
  background-color: #d8d8d8;
  margin-bottom: 24px;
}

.spatiles {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0.5rem;
  width: 100%;
  margin-top: 1rem;
  padding-bottom: 3.2rem;
}

.spacardsize {
  width: 100%;
}

.banner {
  width: auto;
  height: 144px;
  border-radius: 5px;
}

.menuWrapper {
    position: fixed;
    right: 0px;
    bottom: 9rem;
    overflow: scroll;
    z-index: 9998;
    background: #fff;
    min-width: 9rem;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    max-height: 17rem;
}
.overlay {
  position: fixed;
  background-color: #fff;
  filter: brightness(0.2);
  opacity: 0.6;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}

.menuMainTitle {
  margin-bottom: 0.5rem;;
}
