.cloth{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  width: 75%;
}

.cloth>h6{
    font-size: 14px;
    font-weight: 500;
    margin: 0
}
.laundryCard{
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 0.5rem;

}
.laundryCard>span{
    font-size: 14px;
    margin: 5px 0;
    font-weight: 500;
}

.addButtonWrapper{
}

.addButtonWrapper>button{
    width: 100%;
}