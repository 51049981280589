.green-divider {
    border-bottom: 4px solid var(--primary-color);
    margin: 1rem 0;
}
.disabledbutton {
    pointer-events: none;
    color: gray !important;
}
.chat-support {
    text-decoration: none;

    .fa-whatsapp {
        font-size: 25px;
        color: #75716f;
    }
}

.categories-wrapper {
    box-shadow: 0px 0px 3px #0000002e;
    background: #fff;
    margin-bottom: 1rem;
    border-radius: 5px;
    overflow: hidden;
    .category-title {
        font-size: 1rem;
        line-height: 1.4;
    }
    .category-desciption {
        font-size: 0.8rem;
    }
    .category-options {
        display: none;

        label {
            max-width: 15rem;
            word-break: break-word;
        }
        &.show {
            padding: 10px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
        }
    }
    .category-content {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        background-color: var(--primary-color);
        color: #fff;
    }
    .category-img {
        width: 100%;
        height: 100%;
        min-height: 10rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 12px 12px 0 0;
    }
}

.main-card-tile {
    width: 100%;
    display: block;
}
.btn-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 2rem;
    border: 1px solid rgba(181, 181, 181, 1);
    border-radius: 0.5rem;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    background: #fff !important;
    color: #000 !important;
    .fa-mius,
    .fa-plus {
        font-size: 10px;
        padding: 0px 5px;
    }
}
.btn-update-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 5rem;
    height: 2rem;
    border: 1px solid #000000;
    border-radius: 0.5rem;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    padding: 0;
    background: #fff !important;
    color: #000 !important;

    span {
        float: left;
        width: 33%;
        height: 100%;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        &.item-count {
            background-color: #74716f2e;
            color: #000;
            font-size: 15px;
        }
    }
}

.tagcontainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    list-style-type: none;
    gap: 4px;
}
.tag {
    font-size: 9px;
    background: #e23744;
    padding: 4px 6px;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0.1px;
}
.tag-bg-bronze {
    background-color: #bba275;
}
.tag-aqua {
    background-color: #c1e1c1;
    color: #000000;
}
.ird-checkout-warning {
    position: sticky;
    z-index: 9;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    display: flex;
    margin: 0;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    background-color: #bebebe;
    border: none;
}

.welcomeLayout {
    P {
        margin-bottom: 1rem;
    }
}
.roomNumberInput {
    border-radius: 5px;
    padding: 7px 12px;
    width: 100%;
    border: 1px solid #e5e5e5;
    font-size: 14px;
    color: #121b2b;
    outline: 0;
    letter-spacing: 0.5px;
    background: #fff;
    box-shadow: none;
    min-height: 44px;
    min-width: 140px;
}
.datepicker,
.datepicker-modal {
    position: fixed !important;
}

.filter-veg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.out-of-service {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid #d82550;
    border-radius: 7px;
    background: #d77e9436;
    margin-bottom: 0.75rem;
    .text {
        font-size: 14px;
        line-height: 1.2;
        color: #000;
        margin-left: 10px;
    }
}

.gallery_scroller {
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    height: 210px;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    div {
        scroll-snap-align: center;
        margin: 0 5px;
        position: relative;
        &.image_card {
            min-width: 90%;
            min-height: 95%;
            img {
                height: 200px;
                border-radius: 12px;
                width: 100%;
            }
        }
    }
}

.hide {
    display: none;
}
.category-details-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .cat-banner-image {
        width: 100%;
    }
    &.hide {
        display: none;
    }
}

.btn-checkout {
    width: 5rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    overflow: hidden;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    width: 100%;
    box-shadow: none;
    outline: none;
    border: none;
    padding: 0.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #fff;
}

@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: var(--primary-color);
    }
}

.skelton-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    border: 1px solid #efefef;
    margin: 1rem 0;
    border-radius: 0.5rem;
    .skelton-profile-pic,
    .skelton-img-big,
    .skelton-description {
        background: #eee;
        background: linear-gradient(
            110deg,
            #ececec 8%,
            #f5f5f5 18%,
            #ececec 33%
        );
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
    }
    .skelton-profile-pic {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        margin-bottom: 0.5rem;
    }
    .skelton-img-big {
        width: 100%;
        height: 10rem;
        border-radius: 0;
        margin-bottom: 0.5rem;
    }
    .skelton-description {
        width: 100%;
        height: 1rem;
        margin-bottom: 0.5rem;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.mobile-input-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0 1rem;
    select {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #c0c0c0;
        padding: 10px 3px;
        box-sizing: border-box;
        font-size: 14px;
        font-style: italic;
        margin: 0;
        margin-right: 1rem;
    }
}
.recommend-title {
    width: 100%;
    flex: 1 1 100%;
    padding: 0.25rem 1rem;
    text-align: center;
    position: relative;
    z-index: 1;
    span {
        background: #fff;
        padding: 0 15px;
    }
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: #d5d5d5;
        width: 100%;
        height: 1px;
        left: 0;
        z-index: -1;
    }
}
.recommended-items-container {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.75rem;
    justify-content: flex-start;
    overflow: auto;
    padding: 0.25rem 0.25rem;
    position: relative;
    width: 100%;
    z-index: 1;

    .item-food-card {
        align-items: stretch;
        flex-direction: unset;
        margin-bottom: 0;
        .item-details-wrapper {
            flex-direction: column;
            min-width: 10rem;
            padding: 3px;
            border: 1px solid #f5f5f5;
            gap: 5px;
            justify-content: space-between;
            .item-title-wrapper {
                flex-direction: column;
                padding: 0px 3px;
                .item-title-price-wrapper {
                    width: 100%;
                }
                .item-price {
                    font-size: 12px;
                    font-weight: 600;
                }
                .item-actions {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    margin: 10px 0;
                    align-items: center;
                    .btn-add {
                        width: 3.5rem;
                        height: 1.7rem;
                        font-size: 11px;
                        border-radius: 6px;
                    }
                }
            }
            &.small_thumbnail {
                .item-thumbnail {
                    border-bottom: 1px solid #ececec;
                }
                .item-image {
                    width: 100%;
                    max-width: 100%;
                    height: 7rem;
                    object-fit: cover;
                    object-position: center center;
                }
                .icon-img {
                    top: auto;
                    bottom: 5px;
                    left: 5px;
                }
            }
        }
    }
}

.preOrder-wrapper {
    &.on {
        border: 1px dashed #c2c2c2;
        border-radius: 12px;
        padding: 10px;
        background: #f3f3f3;
    }
}
.sign-pad {
    width: 100%;
    background: #dfedfb;
    border-radius: 1rem;
    border: 1px dashed #2e95ff;
}
.ant-select {
    .ant-select-selector {
        border-radius: 5px;
    }
}
.ant-drawer {
    font-family: inherit;
    .ant-drawer-content {
        border-radius: 5px 5px 0 0;
    }
    .ant-drawer-header {
        padding: 16px 0.5rem;
        .ant-drawer-header-title {
            flex-direction: row-reverse;
            .ant-drawer-close {
                margin-right: 0;
            }
        }
    }
    .ant-drawer-body {
        padding: 0.5rem;
    }
}
.language-modal {
    .content-area {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 8px;
        .lang-card {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            width: 100%;
            border: 1px solid #e8e8e8;
            border-radius: 8px;
            overflow: hidden;
            padding: 0.5rem;
            gap: 0.25rem;
            background: #fff;
            &.selected {
                justify-content: space-between;
                border: 2px solid green;
                background-color: rgba($color: green, $alpha: 0.1);
            }
        }
    }
}
// .overlay-lang{
//     filter: blur(15px);
//     position: fixed;
//     top: 50%;
//     left: 50%;
//     width: 100%;
//     height: 100%;
//     transform: translate(-50%, -50%);
//     background: #1010106b;
// }
// .content-area{
//     width: 20rem;
//     height: 20rem;
//     background-color: #fff;
//     z-index: 9;
//     border-radius: 12px;
//     padding: 1.5rem;
//     box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2);
// }

.laundrycard {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    border: 1px solid #efefef;
    border-radius: 5px;
    overflow: hidden;
    padding: 0.5rem;
    gap: 0.25rem;
    background: #fff;
}
.header-new {
    height: 51px;
    background-color: #fffffff2;
}
.bg-gray {
    background-color: #f3f3f3;
}
table {
    tr,
    td {
        border-width: 1px;
        border-color: #828282;
        padding: 4px;
    }
}

.category-name {
    // background-color: #002144;
    background-color: var(--primary-color);
    color: #ffffff;
    padding: 4px;
    width: fit-content;
    min-width: 30%;
    position: relative;
    min-height: 30px;
    padding-left: 0.75rem;
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-left: -0.5rem;
    max-width: 80%;
    &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 0px;

        border-bottom: 15px solid var(--primary-color);
        border-top: 15px solid transparent;
        border-left: 15px solid var(--primary-color);
        border-right: 15px solid transparent;
        left: 100%;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        width: 0px;
        border-bottom: 15px solid transparent;
        border-top: 15px solid var(--primary-color);
        border-left: 15px solid var(--primary-color);
        border-right: 15px solid transparent;
        left: 100%;
    }
}


body[dir='rtl'] {
    .category-name {
        margin-right: -0.5rem;
        margin-left: unset;
        &::after {
            content: '';
            position: absolute;
            top: 0px;
            left: unset;
            right: 100%;
            width: 0px;

            border-bottom: 15px solid transparent;
            border-top: 15px solid var(--primary-color);
            border-left: 15px solid transparent;
            border-right: 15px solid var(--primary-color);
        }
        &::before {
            content: '';
            position: absolute;
            bottom: 0px;
            left: unset;
            right: 100%;
            width: 0px;
            border-bottom: 15px solid var(--primary-color);
            border-top: 15px solid transparent;
            border-left: 15px solid transparent;
            border-right: 15px solid var(--primary-color);
        }
    }
}

body[langcode='ru'] {
    .homepage-container .allservices-container .service_title,
    .services-wrapper .title,
    .menuParagraph,
    .cat-name,
    .login,
    .headingWrapper .card-heading,
    .dishName > h6,
    .addButtonWrapper > button,
    .service-checkout-wrapper .day,
    .tax__val,
    .signatureButtonsWrapper > button,
    .laundryService > h5,
    .categoryContainer > p,
    .spaService > h5,
    .root > h4,
    .datePickerWrapper .value-field,
    .laundryService .laundry-cat-name,
    .welcome-card-new .welcomeCard-heading {
        text-transform: unset;
    }
}
