.checkout-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    text-align: center;
    min-height: 50px;
    direction: ltr;

    .service-title {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: inherit;
      text-transform: capitalize;
      color: #483636;
      max-width: 80%;
      margin: auto;
    }
  }
.service-checkout-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0.5rem;
  padding-bottom: 3.2rem;



  .days {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;

    .slots {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      white-space: nowrap;
      overflow-y: visible;
      overflow-x: auto;
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
      .day {
        background: #f8f8f8;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-weight: 500;
        padding: 10px 10px;
        font-size: 13px;
        flex: 1;
        margin-right: 10px;
        border: 1px solid transparent;
        box-shadow: 0 2px 3px 0 rgba(65, 69, 88, 0.1),
          0 1px 3px 0 rgba(0, 0, 0, 0.07);
        text-transform: capitalize;
        min-width: 7rem;

        &:last-child {
          margin-right: 0px;
        }

        &.selected {
          color: #fff;
          border: 1px solid var(--primary-color);
          background: var(--primary-color);
        }
      }

      .label {
        font-weight: 600;
        flex: 1;
        font-family: inherit;
        font-size: 15px;
        letter-spacing: 0.4px;
        color: #333;
      }
    }

    .noofguest {
      flex: 1;
      border-radius: 5px;
        padding: 13px 12px;
        width: 100%;
        border: 1px solid #e5e5e5;
        font-size: 14px;
        color: #121b2b;
        outline: 0;
        letter-spacing: 0.5px;
        background: #fff;
        box-shadow: none;
        min-height: 44px;
    }

    .room_number {
      flex: 1 1;
      border: none;
      outline: none;
      background: #ffffff;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      padding: 12px 12px;
      font-size: 15px;
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      text-align-last: center;
      -moz-text-align-last: center;
      color: #000000;
      border: 1px solid #949494;
    }

    .room_Text {
      text-align: center;
    }
  }

  .time-slots {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
  }

  .time-slots .slot {
    background: #e2e7ed;
    border-radius: 5px;
    margin: 0 0 10px;
    padding: 13px 4px;
    width: calc(50% - 5px);
    text-align: center;
    box-sizing: border-box;
    box-shadow: 0 2px 3px 0 rgba(65, 69, 88, 0.1),
      0 1px 3px 0 rgba(0, 0, 0, 0.07);
    letter-spacing: 0.4px;
    font-size: 13px;
    font-weight: 500;
    align-items: center;
    border: 1px solid var(--primary-color);
    background: #fff;
    color: #344050;
    font-family: inherit;
    display: flex;
    flex-direction: column;
  }

  .time-slots .slot.full {
    background: #c2c2c2;
    color: #acacac;
    border-color: #acacac;
    pointer-events: none;
    user-select: none;
  }
  .time-slots .slot.hide {
    display: none;
    //   background-color: #000;
  }

  .time-slots .slot.selected {
    background: var(--primary-color);
    color: #fff;
    border: 1px solid var(--primary-color);
  }

  .slots-title {
    font-weight: 500;
    line-height: 1.3;
    color: #3c4858;
    margin-bottom: 10px;
    font-size: 16px;
    width: 100%;
    flex: 1 1 100%;
  }

  .footer-btn-wrapper {
    width: 100%;
    position: fixed;
    background: #fff;
    padding: 10px 0;
    bottom: 0;
    left: 0;
    z-index: 9;
  }

  .confirm-book-btn {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 15px;
    flex: 1 1;
    box-shadow: 0 2px 3px 0 rgba(65, 69, 88, 0.1),
      0 1px 3px 0 rgba(0, 0, 0, 0.07);
    background: var(--primary-color);
    box-sizing: border-box;
    border: none;
    color: #fff;
    position: relative;
    margin: auto;
    letter-spacing: 0.75px;
    font-family: inherit;

    &.hidden {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .description_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    label {
      font-weight: 600;
      flex: 1 1;
      font-size: 15px;
      letter-spacing: 0.4px;
      color: #333;
      margin-bottom: 0.5rem;
    }
    .textarea_description {
      resize: none;
      padding: 6px;
      border: 1px solid #949494;
      border-radius: 5px;
      font-size: 15px;
      font-weight: 500;
      min-height: 70px;
      outline: none;
      box-shadow: none;
      font-family: inherit;
    }
  }
}

.error {
  margin: 0px;
  padding: 0px 10px;
  font-size: 12px;
  text-align: center;
  margin-bottom: 10px;
  color: #b91e1e;
  font-weight: 500;
}
