.priceDetails-wraper {
  display: flex;
  background-color: #fff;
  box-sizing: border-box;
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 5px;
  border:1px dashed #f5f5f5;
  gap: 0.25rem;
  .details-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .type_label,
    .value_label {
      color: #1c1c1c;
      text-transform: capitalize;
      font-weight: 500;
      font-size: 0.9rem;
      white-space: nowrap;
    }
    &.grand_total {
      .type_label,
      .value_label {
        font-weight: 600;
        font-size: 1rem;
      }
    }
  }
}
