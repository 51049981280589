.orderFoodLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 0.5rem;
  padding-bottom: 100px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding-top: 3rem;
}
.orderFoodLayout > .arrowBack {
  width: 36px;
  margin-bottom: 12px;
}
.arrowBackWrapper {
  background: #fff;
  position: fixed;
  top: 65px;
  width: 100%;
  z-index: 9;
  left: 0px;
  padding-left: 17px;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.16);
}
.arrowBack {
  width: 36px;
  margin-bottom: 0px;
}
.orderFoodLayout > .banner {
  width: auto;
  height: 144px;
  border-radius: 8px;
}

.fixedLayout {
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 0.5rem;
  padding-bottom: 9rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 3rem;
  color: #1c1c1c;
}
.fixedLayout > .arrowBack {
  width: 36px;
  margin-bottom: 12px;
}
.fixedLayout > .banner {
  width: auto;
  height: 144px;
  border-radius: 8px;
}
.menuMainTitle > h6 {
  font-weight: 500;
  font-size: 16px;
}
.dishesCards {
  width: 100%;
}
.menuMainTitle > p {
  margin: 0 0 0.75rem 0;
  font-size: 0.75rem;
  font-weight: 500;
  max-width: 100%;
  line-height: 20px;
  margin-bottom: 0;
}
.saladsBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.saladsBoxTitle {
  width: 100%;
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.categoryContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.saladsBoxTitle > p {
  margin: 0;
  color: #000000;
  letter-spacing: 0.5px;
  width: 100%;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 500;
}
.categoryContainer > p {
  margin: 0;
  font-size: 16px;
  color: #696969;
  letter-spacing: 1px;
  text-transform: uppercase;
  max-width: 80%;
  text-align: center;
  background: #f3f3f3;
  z-index: 1;
  padding: 0 10px;
}
.categoryContainer {
  position: relative;
}
.categoryContainer::after {
  content: '';
  position: absolute;
  top: 50%;
  background: #696969;
  width: 100%;
  height: 1px;
  transform: translateY(-50%);
}
.menuWrapper {
    position: fixed;
    right: 0px;
    bottom: 9rem;
    overflow: scroll;
    z-index: 9998;
    background: #fff;
    min-width: 9rem;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    max-height: 17rem;
}
.overlay {
  position: fixed;
  background-color: #fff;
  filter: brightness(0.2);
  opacity: 0.6;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}

.noOverlay {
  display: none;
}
.cartCounterWrapper {
  z-index: 9;
  position: fixed;
  bottom: 45px;
  width: 100%;
  background: #fff;
  padding: 1rem 0;
  left: 0px;
  padding: 0.5rem 1rem;
  border: none;
}
.hideMenu {
  display: none;
}
.irdtiles {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0.5rem;
  width: 100%;
  padding-bottom: 3.2rem;
}
.irdcardsize {
  width: 100%;
}
