.checkoutLayout {
  background-color: #f3f3f3;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 50px 20px;
  padding-top: 50px;
  padding-bottom: 200px;
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.checkoutLayout::-webkit-scrollbar {
  display: none;
}
.checkoutLayout > h3 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 0;
}

.checkoutLayout > p {
  font-size: 12px;
}

.closeButton {
  position: fixed;
  top: 15px;
  right: 20px;
  z-index: 99999;
  font-size: 18px;
  font-weight: 500;
}

.pickUpLaundry {
  display: flex;
  align-items: center;
  padding: 24px 0;
  /* border-bottom: 2px solid #696969;; */
}

.pickUpLaundry > p {
  font-size: 12px;
  margin: 0 0 0 12px;
}
.timeInput {
  height: 32px;
  color: #696969;
  width: 100%;
  border: none;
  padding: 1px;
  background-color: #f3f3f3;
}
.timeInput::-webkit-datetime-edit {
  -webkit-flex: 1;
  -webkit-user-modify: read-only !important;
  display: inline-block;
  min-width: 0;
  overflow: hidden;
}

.timeInput::-webkit-datetime-edit-fields-wrapper {
  -webkit-user-modify: read-only !important;
  display: inline-block;
  padding: 1px 0;
  white-space: pre;
}

.selectTime {
  font-weight: 600;
  font-size: 13px;
  margin-top: 0;
  text-transform: uppercase;
}

.checkoutBox {
  height: 64px;
  position: fixed;
  box-sizing: border-box;
  left: 0;
  bottom: 50px;
  right: 0;
  background-color: #fff;
  z-index: 9;
  display: flex;
  justify-content: space-around;
  align-items: center;
  filter: drop-shadow(0px -1px 3px #69696960);
}

.warning {
  position: absolute;
  top: 55px;
  z-index: 9;
  left: 0;
  right: 0;
  font-size: 11px;
  font-weight: 500;
  width: 100%;
  display: flex;
  margin: 0;
  padding: 6px;
  box-sizing: border-box;
  background-color: #c0c0c050;
}
.taxDisclaimer {
  margin: 0 0 5px 0;
  font-size: 9px;
  font-style: italic;
  font-weight: 500;
}

.timeInputCheckbox {
  height: 21px;
  width: 21px;
  background-color: #7d9371;
}
.placeRequestContentIntro {
  font-size: 16px !important;
  font-weight: 500;
}
.placeRequestContent {
  font-size: 13px !important;
  font-weight: 500;
  margin: 6px 0;
}
.placeRequestContentBold {
  font-weight: 500 !important;
  font-size: 13px !important;
  flex: 1;
}
.roomNumber {
  border-top: 2px solid #696969;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.roomNumber > input {
  flex: 1;
}
.roomNumberNoBorder {
  display: flex;
  justify-content: space-between;
}
.roomNumberNoBorder > p {
  margin: 0;
}
.errorMessage {
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: #1c1c1c;
    font-size: 0.85rem;
    line-height: 1.25;
    gap: 0.5rem;
    background-color: #cde8f6;
    padding: 0.5rem;
    border-radius: 6px;
    border: 1px solid gold;
    background: #ffd7003d;
}
.payNowWindow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.total {
  display: flex;
  background-color: #fff;
  box-sizing: border-box;
  padding: 8px 0;
  width: 100%;
  margin-bottom: 24px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.total > div > p {
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0;
}
.total > h3 {
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
}

.timeInputWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.timeInputWrapper > p {
  margin: 0;
}

.price {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 0.9rem;
  margin-bottom: 0.9rem;
  width: 100%;
}
.price__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  width: 100%;
}
.grand_pay {
  color: #1c1c1c;
  font-weight: 600;
}
.price__label {
  color: #9c9c9c;
  font-size: 0.75rem;
}
.grand__pay__label {
  color: #1c1c1c;
  font-weight: 600;
  font-size: 1rem;
}
.tax__val {
  color: darkgrey;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9rem;
  white-space: nowrap;
}
.grand__pay__val {
  color: #1c1c1c;
  font-weight: 600;
  font-size: 1rem;
}
