.textArea{
    resize: none;
    height: fit-content;
    min-height: 50px;

    display: block;
    width: 100%;
    /* height: 48px; */
    border: 1px solid #d1d4d5;
    padding:12px;
    background: #fff;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    color: #1b1b19;
}