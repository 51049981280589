.dishName{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.dishName>img{
    width: 18px;
    margin-right: 6px;
}
.dishName>h6{
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    width: 60%;
    text-transform: capitalize!important;
}
.menuCard{
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 0.5rem;
    width: 100%;

}
.menuCard>span{
    font-size: 13px;
    margin: 5px 0;
    font-weight: 500;
}
.menuCard>span>ul{
    margin: 0;
    padding-left: 25px;
}
.menuCard>span:last-child{
    font-size: 11px;
}
.addButtonWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.taxedPrice{
    font-size: 12px;
}
.inclTaxes{
    font-size: 10px;
    font-weight: 500;
    font-style: italic;
}

.addonList {
    width: 80%;
    margin: 0;
    padding: 0px;
    list-style: none;
    /* margin-top: 1.5rem; */
}
.addonListTitle{
    font-size: 14px;
    color: #000;
    font-weight: 500;
    margin: 0;
}
.addonListitem{
    padding-left: 1rem;
}