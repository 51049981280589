.item-food-card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  .item-type {
    margin-right: 5px;
    img {
      width: 16px;
      height: 16px;
      object-fit: contain;
      margin-top: 3px;
    }
  }
  .item-details-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    // border: 1px solid #e8e8e8;
    border-radius: 5px;
    overflow: hidden;
    padding: 0.5rem;
    gap: 0.25rem;
    background: #fff;
    // .item-actions{
    //     display: flex;
    //     align-items: flex-end;
    //     flex-direction: column;
    //     justify-content: flex-end;
    // }
    .item-thumbnail {
      position: relative;
    }
    .item-type {
      position: static;
      left: 0;
      bottom: 0;
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      top: 0;
      right: 0;
    }
    &.big_thumbnail {
      flex-direction: column;
      padding: 0rem;
      .item-title-wrapper {
        padding: 0.5rem;
        gap: 4px;
      }
      .item-image {
        display: flex;
        width: 100%;
        height: 180px;
        object-fit: cover;
      }
      .icon-img {
        position: absolute;
        left: 15px;
        bottom: 15px;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        top: auto;
        right: auto;
        background-color: #fff;
      }
    }
    &.small_thumbnail {
      .item-title-wrapper {
        padding: 0 0.25rem 0 0.75rem;
      }
      .item-image {
        display: flex;
        object-fit: cover;
        min-width: 100px;
        height: 100px;
        max-width: 100px;
        border-radius: 8px !important;
      }
      .icon-img {
        position: absolute;
        left: auto;
        bottom: auto;
        width: 15px;
        height: 15px;
        min-width: 15px;
        min-height: 15px;
        border-radius: 3px;
        top: 8px;
        right: 8px;
        background-color: #fff;
      }
    }
    &.no_thumbnail {
      .item-image {
        display: none;
      }
    }
    &.out-of-stock {
      opacity: 0.35;
      .btn-update-item {
        background-color: #f00;
      }
    }
  }
  .item-title-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .item-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      margin: 0px;
      white-space: pre-wrap;
    }
    .item-price {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      margin: 0px;
      white-space: nowrap;
    }
  }

  .item-description {
    font-size: 0.75rem;
    font-weight: 500;
    max-width: 100%;
    margin: 0px;
    line-height: 20px;
    white-space: pre-wrap;
  }

  .customizable {
    line-height: 1rem;
    display: inherit;
    font-size: 0.5rem;
    margin-top: 1px;
    color: rgba(244, 162, 102,1);
    text-align: center;
    margin: 0;
  }
}

.fixedLayout {
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0.5rem;
  padding-bottom: 140px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #1c1c1c;
  gap: 1rem;
  .arrowBack {
    width: 36px;
    margin-bottom: 12px;
  }
  .banner {
    width: auto;
    height: 144px;
    border-radius: 8px;
  }
  &.bg-image {
    background-image: url('https://master-vserve.s3.ap-south-1.amazonaws.com/hotels/menu_bg_image_upload.jpg');
    background-position: 0 0;
    background-size: contain;
    color: #fff;
  }
}
.cat-banner-image {
  height: 200px;
  border-radius: 8px;
}
