.signatureCanvas {
    background-color: #f3f3f3 !important;
    width: 100%;
    height: 160px;
    border-radius: 8px;
    z-index: 9999;
}

.layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
}

.thankYouBox {
    width: 100%;
    position: absolute;
    height: 100px;
    background-color: #6d716b;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999999;
    box-shadow: 0px 5px 10px #9e9e9e;
    padding: 40px 0;
    color: #fff;
    text-align: center;
}
.thankYouBox > p {
    font-size: 20px;
    font-weight: 500;
}

.switchContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.switchContainer > p {
    font-size: 13px;
}
.signatureScreen > p {
    font-size: 13px;
}
.signatureButtonsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.signatureButtonsWrapper > button {
    padding: 8px 24px;
    width: fit-content;
    border: none;
    color: #fff;
    background-color: #ff5722;
    font-size: 13px;
    text-transform: capitalize;
    border-radius: 4px;
}

.submitButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.submitButtonWrapper > button {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    background-color: var(--primary-color);
    filter: drop-shadow(0px 3px 2px #69696950);
    display: flex;
    padding: 10px 30px;
    border: none;
    border-radius: 4px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.disableBtn {
    background-color: #bcbcbc !important;
    filter: none !important;
    color: #8a8a8a !important;
    cursor: not-allowed;
}
.labelSize {
    font-size: 13px;
}
.InputCheckBox {
    margin-right: 10px;
}

.disclaimerWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.total {
    display: flex;
    background-color: #fff;
    box-sizing: border-box;
    padding: 8px;
    width: 100%;
    margin-bottom: 24px;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}
