.main-content {
    p {
        font-weight: 500;
        color: #483636;
        margin: 0;
        font-size: 14px;
    }
}

.terms-wrappper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
    gap: 0.3rem;

    input[type='checkbox'] {
        margin: 0;
        margin-right: 5px;
        margin-top: 5px;
    }
    label {
        font-size: 12px;
        font-weight: 500;
        p {
            text-align: left;
            display: inline;
            font-weight: 500;
            color: #727272;
            font-size: 13px;
            line-height: 1.3;
        }
    }
    .terms-descp {
        max-height: 12rem;
        overflow: hidden;
        overflow-y: auto;
        font-size: 12px;
        margin-top: 11px;
    }
}

.actions {
    display: flex;
    align-items: center;
    .btn-dialog {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        padding: 10px 10px;
        color: #483636;
        letter-spacing: 0px;
        flex: 1 1;
        font-family: inherit;
        outline: none;
        text-transform: capitalize;
        background: #fff;
        border: 1px solid #c0c0c0;
        border-radius: 0.5rem;
        width: 95%;
        margin: 0 2px;
        font-size: 0.9rem;

        &:last-child {
            border-left: 1px solid #d8d8d8;
        }

        &.btn-confirm {
            font-weight: 700;
            color: #fff;
            background-color: var(--primary-color);
            border-color: var(--primary-color);
            &.disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }
}
