.inputWrapper{
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
}
.inputWrapperEmpty{
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
}
.inputWrapper>input{
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #c0c0c0;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
    font-style: italic;
    margin: 10px 0; 
    border-radius: 0;
}
.inputWrapperEmpty>input{
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #c0c0c0;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
    font-style: italic;
    margin: 10px 0; 
    border-radius: 0;
}
.inputWrapper>input::placeholder{
    font-style: italic;
    font-size: 14px;
    color: #69696980
}
.inputWrapper>input:focus{
    border:none;
    outline: none;
    border-bottom: 1px solid green;
}
.inputWrapperEmpty>input::placeholder{
    font-style: italic;
    font-size: 14px;
    color: #69696980
}
.inputWrapperEmpty>input:focus{
    border:none;
    outline: none;
    border-bottom: 1px solid red!important;
}


.success:focus{
    border-bottom: 1px solid green!important;
}
.error:focus{
    border-bottom: 1px solid red!important;
}