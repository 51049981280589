.item-title-wrapper .item-price .item-title {
    display: flex;
}
.addon-item-main-title {
    font-size: 1rem;
    font-weight: 600;
    color: #1c1c1c;
    line-height: 150%;
    margin: 0px;
}
.addon-item-header {
    margin: 0.5rem 0;
    .addon-item-title {
        font-size: 0.9rem;
        font-weight: 600;
        color: #1c1c1c;
        line-height: 150%;
        margin: 0px;
    }
    .addon-item-descp {
        font-size: 0.6rem;
        font-weight: 300;
        color: #1c1c1c;
        line-height: 150%;
        margin: 0px;
    }
}
.btn-item-add {
    border: 1px solid var(--primary-color);
    color: #fff;
    background-color: var(--primary-color);
    box-shadow: none;
    margin-top: auto;
    letter-spacing: 0.5px;
    font-size: 16px;
    padding: 10px;
    border-radius: 8px;
    font-weight: 500;
    width: 100%;
}

.addons-list {
    padding-left: 0.6rem;
    .subaddon-card {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: center;
        .subaddon-title,
        .subaddon-price {
            font-size: 0.8rem;
            font-weight: 500;
            color: #1c1c1c;
            line-height: 150%;
            margin: 0px 0px 0px 7px;
        }
        .subaddon-price {
            font-size: 0.6rem;
            font-weight: 500;
        }
        .name-wrapper {
            display: flex;
            justify-content: flex-start;
        }
        input[type='radio'],
        input[type='checkbox'] {
            width: 18px;
            height: 18px;
        }
    }
}

//  addon confirm
.item-food-card {
    margin-bottom: 0;
    &:first-child {
        padding-top: 0;
    }
}

.btn-new-customization {
    font-size: 13px;
    padding: 10px 15px;
    border-radius: 8px;
    font-weight: 500;
    border: 1px solid var(--primary-color);
    color: #fff;
    background-color: var(--primary-color);
    display: block;
    width: 100%;
}
.selectedAddonList {
    padding: 0px;
    padding-left: 15px;
    margin: 10px 0 0;
    white-space: pre-wrap;
    li {
        font-weight: 500;
        font-size: 12px;
        margin: 0 0 6px 0;
        color: #000000;

        span.addon-name-wrapper {
            color: #868686;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        span.addon-name {
            color: #000;
            // white-space: nowrap;
        }
    }
}
