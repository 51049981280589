.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999999998888899;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading svg {
  fill: var(--primary-color);
}
.loading p {
  margin: 0;
  color: var(--primary-color);
}
