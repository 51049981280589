a[href] {
    color: blue;
    text-decoration: underline;
}
h1 {
    font-size: 2em;
}
h2 {
    font-size: 1.5em;
}
h3 {
    font-size: 1.17em;
}
h5 {
    font-size: 0.83em;
}
h6 {
    font-size: 0.75em;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 800;
}
li,
p {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 20px;
}
hr {
    color: var(--primary-color);
    border-top-width: 1px;
}

b,
strong {
    font-weight: 700;
}

img {
    object-fit: cover;
    object-position: center;
    display: revert;
    vertical-align: revert;
    height: revert-layer;
}
label {
    font-size: 14px;
    display: block;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: 0.009em;
    color: #1b1b19;
}

ul {
    padding-left: 3rem;
    list-style-type: disc;
}

ol {
    padding-left: 1rem;
    list-style-type: decimal;
}

.datepicker-col-1 li {
    text-align: center;
}

.veg-healthy-wrapper {
    .ant-switch-checked {
        background-color: green !important;  /* Change to green when checked */
        border-color: green !important;
      }
}
