.reuseLayout{
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 0.5rem;
    padding-bottom: 3.2rem;
}
.reuseLayout>.arrowBack{
  width: 36px;
  margin-bottom: 12px;
}

.reuse>p{
 margin-bottom: 1rem; 
}
