.confirmation-main {
  background-color: #00000080;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99999;
}
.comfirmation-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  padding: 10px 24px;
  overflow-y: scroll;
  height: 100vh;
}
.action-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
}
.closeAddon {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #696969;
}
.terms-checkbox {
}
.terms-checkbox input {
  margin: 0;
  margin-right: 10px;
}
.back {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  background-color: #f4f5f8;
  filter: drop-shadow(0px 3px 2px #69696950);
  display: flex;
  padding: 10px 30px;
  border: none;
  border-radius: 4px;
}
.proceed {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  background-color: var(--primary-color);
  filter: drop-shadow(0px 3px 2px #69696950);
  display: flex;
  padding: 10px 10px;
  border: none;
  border-radius: 4px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
