// feed tile

// .feed-tile {
//     cursor: pointer;
//     width: 100%;
//     border-radius: 0;
//     height: 380px;
//     position: relative;
//     background-size: cover;
//     display: flex;
//     justify-content: flex-start;
//     align-items: flex-end;
//     padding: 1rem;
//     box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2);
//     margin-top: 7px;
//     width: 100%;
//     &::before {
//         background-color: rgba(0, 0, 0, 0.2);
//         background: linear-gradient(180deg, transparent, #000000e0);
//         content: '';
//         display: block;
//         width: 100%;
//         height: 40%;
//         position: absolute;
//         border-radius: 0;
//         left: 0;
//         bottom: 0;
//     }

// }

//feed page
.feed-wrapper {
    width: 100%;
    padding: 0.5rem;
    padding-bottom: 3.2rem;
    background-color: #f3f3f3;
    .feed-list-container {
        min-height: 100vh;
    }
}
// feed card

.feed-card {
    width: 100%;
    border: 1px solid #ececec;
    margin-bottom: 0.8rem;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    // box-shadow: 0 0 1px #c9c9c9;
    .feed-body {
        width: 100%;
        background: #000;

        .feed-content {
            min-height: 25rem;
            max-height: 25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .mute-icon {
                width: 30px;
                height: 30px;
                position: absolute;
                bottom: 10px;
                right: 10px;
                background-color: #fff;
                border-radius: 50%;
                background-size: 57%;
                background-repeat: no-repeat;
                background-image: url('../../assets/images/audio.png');
                background-position: center center;
                &.muted {
                    background-image: url('../../assets/images/mute.png');
                }
            }
            img {
                width: 100%;
                border: 0;
                object-fit: contain;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
            .pin-icon {
                margin-top: 3px;
                z-index: 99;
                position: absolute;
                top: 10px;
                left: 10px;
                background: #ffffff1c;
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                .fas {
                    transform: rotate(30deg);
                    color: tomato;
                }
            }
        }
    }
    .feed-footer {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 0.5rem;
        .action-conainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            font-size: 1rem;
            color: #000;
            width: 100%;
            .left-sec,
            .right-sec {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 0.2rem;
                .btn-interest {
                    padding: 6px 13px;
                    margin-left: 0.5rem;
                    letter-spacing: 0.35px;
                    font-size: 12px;
                    background-image: linear-gradient(45deg, #fcecfe, #e6fcf5);
                    border: 1px dashed #13377d;
                    color: #13377d;
                    position: relative;
                    overflow: hidden;
                    // &:after {
                    //     background: #13377d4a;
                    //     content: '';
                    //     width: 100%;
                    //     height: 100%;
                    //     opacity: 0.2;
                    //     position: absolute;
                    //     animation: interest 5s linear infinite;
                    //     left: 0%;
                    //     top: 0;
                    //     transform: rotate(-45deg);
                    // }
                }
            }

            .btn-like {
                width: 25px;
                height: 25px;
                background-size: 85%;
                background-repeat: no-repeat;
                background-position: center center;
                background-image: url(../../assets/images/like.png);
                margin-right: 0.5rem;
                &.liked {
                    animation: pulse 0.3s linear 1;
                    background-image: url(../../assets/images/liked.png);
                }
            }
        }
        .unlike {
            &.active {
                fill: #000000;
                stroke-width: 1px;
                stroke: 000000;
            }
        }
        .like {
            &.active {
                fill: #ed4956;
                stroke-width: 1px;
                stroke: ed4956;
            }
        }
        .total-views {
            font-weight: 600;
            font-size: 0.85rem;
            color: #000;
            margin: 0rem 0;
            position: relative;
            &:last-child {
                margin-left: 8px;
                padding-left: 8px;
                &::before {
                    content: '';
                    width: 1px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: #eeeeee;
                }
            }
        }
        .feed-description {
            margin-top: 0.5rem;
            span {
                font-size: 0.8rem;
                color: #000;
                &.autor {
                    font-weight: 600;
                    font-size: 0.9rem;
                    margin-right: 0.5rem;
                }
            }
        }
        .feed-postdate {
            color: #a8a8a8;
            font-size: 0.7rem;
            margin: 0.5rem 0 0;
        }
    }
}

.feed-body {
    .carousel .control-dots {
        background: transparent;
        border-radius: 8px;
        left: 50%;
        transform: translateX(-50%);
        width: 50%;
        .dot {
            margin: 0 3px;
            width: 6px;
            height: 6px;
            opacity: 0.5;

            &.selected {
                opacity: 1;
            }
        }
    }
    &.count-1 {
        .carousel-status,.carousel-status{
            display: none;
        }
    }
}

@keyframes pulse {
    25% {
        transform: scale(0.9);
    }
    75% {
        transform: scale(1.1);
    }
}

@keyframes interest {
    0% {
        left: -50%;
    }

    100% {
        left: 100%;
    }
}

.feed-interest-dialog {
    .form-group {
        margin-bottom: 0.65rem;
        .feed-interest-label{
            color: #4b4b4b !important;
            font-size: .85rem !important;
            font-weight: 500 !important;
            margin-bottom: 0.25rem !important;
            display: inline-block !important;
        }
        .form-control {
            width: 100%;
            border-radius: 6px;
            padding: 9px;
            width: 100%;
            border: 1px solid #e5e5e5;
            font-size: 14px;
            color: #121b2b;
            outline: 0;
            letter-spacing: 0.5px;
            background: #fff;
            box-shadow: none;
        }
    }
}
