.services-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0.5rem;
  width: 100%;
  padding-bottom: 3.2rem;
  flex-direction: column;
  gap: 1rem;
  padding-top: 0.5rem;
  background: #f3f3f3;

  .serviceCard {
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    border: 1px solid #ececec;
    background: #ffffff;
  }

  .serviceCard img {
    width: 100%;
  }
  .actions{
    justify-content: flex-end;
    direction: ltr;
  }

  .title {
    color: #000000;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 16px;
    line-height: 1.2;
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  .discription {
    font-size: 12px;
    color: #000000;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  .card-details {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .details {
    width: 100%;
  }

  .btn-book {
    letter-spacing: 0.4px;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    border: 1px solid var(--primary-color);
    border-radius: 7px;
    padding: 7px 15px;
    background: #fff;
    color: #344050;
    font-family: inherit;
  }
}

.no-options {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
