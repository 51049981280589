.connectLayout{
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 0.5rem;
    padding-bottom: 3.2rem;
    position: absolute;
    left: 0;
    right: 0;
}
.connectLayout>.arrowBack{
  width: 36px;
  margin-bottom: 12px;
}

