.thankyou-wrapper {
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;

    img {
        max-width: 5rem;
    }

    p {
        font-size: 15px;
        font-weight: 500;
        margin: 10px 0 0;
    }
}